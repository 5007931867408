var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "globeContainer",
      staticClass: "position-absolute top-10",
      class: [
        _vm.$vuetify.rtl ? "start-0 w-sm-100 w-50" : "end-0",
        this.$route.name === "VrDefault"
          ? "end-0 mt-3 me-n8 me-lg-16"
          : "mt-sm-6 mt-14 me-lg-16 pe-lg-10",
      ],
    },
    [
      _c("canvas", {
        ref: "globe",
        staticClass: "me-lg-0 me-sm-n10 ms-lg-n10 mt-lg-5",
        attrs: {
          width: this.$route.name === "VrDefault" ? "1000" : "700",
          height: this.$route.name === "VrDefault" ? "1000" : "655",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }