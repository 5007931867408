var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl" },
    [
      _c("div", { staticClass: "px-4 pt-4 pb-0" }, [
        _c("p", { staticClass: "font-weight-bold text-h6 text-typo mb-0" }, [
          _vm._v("Sales by Country"),
        ]),
      ]),
      _c(
        "v-card-text",
        { staticClass: "px-0 py-2" },
        [
          _c(
            "v-list",
            { staticClass: "py-0" },
            _vm._l(_vm.countries, function (chat, index) {
              return _c(
                "v-list-item",
                {
                  key: chat.title,
                  staticClass: "px-4 py-2",
                  class: { "border-bottom": index != _vm.countries.length - 1 },
                },
                [
                  _c("v-img", {
                    staticClass: "me-6",
                    attrs: {
                      alt: `${chat.title} avatar`,
                      src: chat.avatar,
                      "max-width": "20px",
                    },
                  }),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "span",
                        { staticClass: "text-xs font-weight-bold text-body" },
                        [_vm._v(" Country: ")]
                      ),
                      _c("v-list-item-title", {
                        staticClass: "text-typo font-weight-bold text-sm mb-0",
                        domProps: { textContent: _vm._s(chat.country) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "span",
                        { staticClass: "text-xs font-weight-bold text-body" },
                        [_vm._v(" Sales: ")]
                      ),
                      _c("v-list-item-title", {
                        staticClass: "text-typo font-weight-bold text-sm mb-0",
                        domProps: { textContent: _vm._s(chat.sales) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "span",
                        { staticClass: "text-xs font-weight-bold text-body" },
                        [_vm._v(" Value: ")]
                      ),
                      _c("v-list-item-title", {
                        staticClass: "text-typo font-weight-bold text-sm mb-0",
                        domProps: { textContent: _vm._s(chat.value) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "span",
                        { staticClass: "text-xs font-weight-bold text-body" },
                        [_vm._v(" Value: ")]
                      ),
                      _c("v-list-item-title", {
                        staticClass: "text-typo font-weight-bold text-sm mb-0",
                        domProps: { textContent: _vm._s(chat.bounce) },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }